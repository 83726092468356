h1,
h2,
h3,
h4,
h5,
h6,
body,
p,
a,
span,
strong {
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.uk-notification-message {
  font-size: 0.95rem;
}

.uk-notification-message-danger {
  background-color: #ca4b4b;
  color: white;
}

.uk-notification-message-success {
  background-color: #2a9664;
  color: white;
}
